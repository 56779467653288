import React from "react";
import "./SignUp.css";

const SignUp = () => {
  return (
    <div id="SignUp">
      <h5>coming soon...</h5>
    </div>
  );
};

export default SignUp;
